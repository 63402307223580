import * as React from 'react';

const IconDelete = (props) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M16.37 7.907c0 .057-.445 5.674-.699 8.038-.159 1.45-1.094 2.33-2.497 2.356-1.078.024-2.133.032-3.171.032-1.102 0-2.18-.008-3.226-.032-1.356-.033-2.292-.93-2.443-2.356-.261-2.372-.697-7.981-.705-8.038a.662.662 0 0 1 .159-.465.592.592 0 0 1 .436-.195h11.558c.166 0 .317.073.436.195a.623.623 0 0 1 .151.465"
      fill="#008638"
    />
    <path
      d="M17.5 4.98c0-.342-.27-.61-.594-.61h-2.43c-.494 0-.924-.352-1.034-.848l-.136-.607a1.654 1.654 0 0 0-1.585-1.248H8.28c-.746 0-1.397.514-1.595 1.288l-.127.568a1.07 1.07 0 0 1-1.034.847h-2.43a.602.602 0 0 0-.595.61v.317c0 .335.27.611.595.611h13.81a.607.607 0 0 0 .595-.61V4.98Z"
      fill="#99FF66"
    />
  </svg>
);

export default IconDelete;
