import { CALL_TYPE_URL, CALL_URL } from 'constants/api';
import httpService from './httpServices';

class CallServices {
    addCall(data) {
        return httpService.post(CALL_URL, data);
    }
    getCalls(params) {
        return httpService.get(CALL_URL, { params: params.queryKey[1] });
    }
    editCall(payload) {
        const { data, id } = payload;
        return httpService.put(`${CALL_URL}/${id}`, data);
    }
    deleteCall(id) {
        return httpService.delete(`${CALL_URL}/${id}`);
    }
    getCallType(params) {
        return httpService.get(CALL_TYPE_URL, { params: params.queryKey[1] });
    }
    addCallJoin(data) {
        return httpService.post(`${CALL_URL}/join-call`, data);
    }
}

export default new CallServices();
