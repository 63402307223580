export const queryKeys = {
  todos: 'todos',
  notis: 'notis',
  countNoti: 'countNoti',
  dataPage: 'dataPage',
  device: 'device',
  deviceDetail: 'deviceDetail',
  config: 'config',
  deviceStatistic: 'deviceStatistic',
  statistic: 'statistic',
  logRequest: 'logRequest',
  logRequestDevice: 'logRequestDevice',
  totalLogRequest: 'totalLogRequest',
  account: 'account',
  call: 'call',
  callType: 'callType',

};

export const roles = {
  ADMIN: "ADMIN",
  USER: "USER",
};
