import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import { RouteBase } from 'constants/routeUrl';
import LoginPage from 'views/Login';
import AppointmentCreate from 'views/AppointmentCreate';
import DefaultLayout from 'layout/DefaultLayout';
import './scss/styles.scss';
import RegisterAccount from 'views/RegisterAccount';

const App = () => {
  useEffect(() => {
    window.addEventListener('offline', (event) => {
      alert('Bạn đang offline');
    });
    return () => {
      window.removeEventListener('offline', (event) => {
        alert('Bạn đang offline');
      });
    };
  }, []);
  //! Render
  return (
    <Router>
      <Routes>
        <Route path={RouteBase.Login} exact element={<LoginPage />} />
        <Route path={RouteBase.AppointmentCreate} exact element={<AppointmentCreate />} />
        <Route path={RouteBase.RegisterAccount} exact element={<RegisterAccount />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
