import * as React from 'react';

const IconChart = (props) => (
  <svg width={20} height={20} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M10.117.91H3.889C1.619.91.333 2.196.333 4.466v6.222c0 2.27 1.286 3.555 3.556 3.555h6.228c2.27 0 3.55-1.285 3.55-3.555V4.466c0-2.27-1.28-3.556-3.55-3.556Z"
      fill="#124072"
    />
    <path
      d="M3.912 5.823a.555.555 0 0 0-.55.557v4.581a.554.554 0 0 0 1.107 0v-4.58a.556.556 0 0 0-.557-.558Z"
      fill="#124072"
    />
    <path
      d="M7.024 3.636a.555.555 0 0 0-.552.557v6.767a.554.554 0 0 0 1.109 0V4.193a.556.556 0 0 0-.557-.557ZM10.093 8.24a.556.556 0 0 0-.557.557v2.163a.554.554 0 0 0 1.108 0V8.797a.555.555 0 0 0-.55-.557Z"
      fill="#115690"
    />
  </svg>
);

export default IconChart;
