export const RouteBase = {
  // Home: '/',
  Login: '/login',
  Test: '/test',
  RegisterAccount: '/register-account',
  AppointmentCreate: '/appointment-create',
  ListAppointment: '/list-appointment',
  CallHistory: "/call-history",
  JoinMeet: "/join-meet",
  ListCall: "/list-call",
  CallingManage: "/calling-manage",
  RecordManage: "/record-manage",
};
