import React from 'react';
import { Formik, Form, Field } from 'formik';
import InputField from 'components/CustomField/InputField';
import { useNavigate } from 'react-router-dom';
import { RouteBase } from 'constants/routeUrl';
import CommonStyles from 'components/CommonStyles';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { REQUIRED_FIELD } from 'helpers/messages';
import login_image from 'assets/images/login/login_image.svg';
import { Box } from '@mui/system';
import CustomField from 'components/CustomField';
import { Grid } from '@mui/material';
import { useRegisterAccount } from 'hooks/auth/useRegisterAccount';
import { showError, showInfo } from 'helpers/toast';

const useStyles = makeStyles((theme) => {
  return {
    backgroundVector: {
      backgroundImage: `url(${login_image})`,
      backgroundSize: 'cover',
      position: 'absolute',
      bottom: 0,
      right: 0,
      height: '100%',
      width: '40%',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    loginForm: {
      padding: '4.5rem 4rem',
      backgroundColor: '#fff',
      borderRadius: '12px',
      zIndex: '2',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        minWidth: '350px',
        width: '100%',
        padding: '1.5rem 1rem',
      },
      '& .login_header': {
        color: theme.custom.colors.green,
        fontWeight: '600 !important',
        textAlign: 'center',
      },
      '& .form_group': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4rem',
        '& .form_input_group': {
          display: 'flex',
          gap: '1.5rem',
          flexDirection: 'column',
          marginBottom: '1.5rem',
          '& .form_input': {
            padding: '12px 16px',
            color: theme.custom.colors.darkgray,
            borderRadius: '12px',
            border: `solid 1px ${theme.custom.colors.darkgray}`,
          },
        },
        '& .form_checkbox': {
          display: 'flex',
          flexDirection: 'row',
          gap: '.5rem',
          alignItems: 'center',
          color: theme.custom.colors.darkgray,
          marginBottom: '1.5rem',
        },
        '& .form_submit': {
          padding: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: theme.custom.colors.darkblue,
          borderRadius: '12px',
          color: theme.custom.colors.white,
        },
        '& .btnGroup': {
          marginTop: '1rem',
          gap: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      },
    },
    form: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      display: 'flex',
      //   gap: '110px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5F5F5',
    },
    eachRow: {
      marginBottom: theme.spacing(),
    },
  };
});

const RegisterAccount = (props) => {
  //! State
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const { isLoading: isRegistingAccout, mutateAsync: regisAccount } = useRegisterAccount();
  const initValuesForm = {
    username: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    gender: '',
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_name'))),
    email: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_email'))),
    phoneNumber: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_phoneNumber'))),
    password: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_password'))),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password incorrect!')
      .required(REQUIRED_FIELD(i18n.t('common:password_confirm'))),
    gender: Yup.string().required(REQUIRED_FIELD(i18n.t('common:account_gender'))),
  });

  const optionsGender = [
    {
      label: 'Male',
      value: 'MALE',
    },
    {
      label: 'Female',
      value: 'FEMALE',
    },
  ];

  //! Function

  const handleSubmit = async (values) => {
    try {
      await regisAccount(values, {
        onSuccess: (response) => {
          showInfo(t('common:account_addSuccess'));
          setTimeout(() => {
            navigate(RouteBase.Login);
          }, 1000);
        },
        onError: (error) => {
          showError(error?.response?.data.messages[0] || 'Error. Please try again!', { autoClose: 3000 });
        },
      });
    } catch (error) {
      showError(error?.response?.data.messages[0] || 'Error. Please try again!', { autoClose: 3000 });
    }
  };

  const handleClickCancel = (setFieldValue) => {
    setFieldValue('name', '');
    setFieldValue('email', '');
    setFieldValue('phoneNumber', '');
    setFieldValue('password', '');
    setFieldValue('confirmPassword', '');
    setFieldValue('gender', '');
  };

  const handleClickLogin = () => {
    navigate(RouteBase.Login);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initValuesForm}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(propsFormik) => (
        <Form className={classes.form}>
          <Box className={classes.backgroundVector}></Box>
          <Box className={classes.loginForm}>
            <CommonStyles.Typography className="login_header" variant="h2" component="h2">
              {t('common:account_register')}
            </CommonStyles.Typography>
            <Box className="form_group">
              <Box className="form_input_group">
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Field name="username" LabelColorPrimary component={InputField} label={t('common:account_name')} />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="email"
                      LabelColorPrimary
                      component={InputField}
                      label={t('common:appointment_email')}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="phoneNumber"
                      LabelColorPrimary
                      component={CustomField.InputField}
                      label={t('common:appointment_phoneNumber')}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="password"
                      type="password"
                      LabelColorPrimary
                      component={CustomField.InputField}
                      label={t('common:appointment_password')}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="confirmPassword"
                      type="password"
                      LabelColorPrimary
                      component={CustomField.InputField}
                      label={t('common:password_confirm')}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Field
                      name="gender"
                      LabelColorPrimary
                      component={CustomField.SelectField}
                      options={optionsGender}
                      label={t('common:account_gender')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className="btnGroup">
                <CommonStyles.Button variant="outlined" onClick={() => handleClickCancel(propsFormik.setFieldValue)}>
                  {t('common:cancel')}
                </CommonStyles.Button>
                <CommonStyles.Button variant="contained" color="error" onClick={handleClickLogin}>
                  {t('common:login')}
                </CommonStyles.Button>
                <CommonStyles.Button type="submit" isLoading={isRegistingAccout}>
                  {t('common:register')}
                </CommonStyles.Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default RegisterAccount;
