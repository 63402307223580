import { lazy } from 'react';
import withErrorBoundary from 'components/HOCs/withErrorBoundary';
import { RouteBase } from 'constants/routeUrl';

const HomePage = lazy(() => import('views/Home'));
const Page404 = lazy(() => import('views/Page404'));
const Test = lazy(() => import('views/Test'));

const RegisterAccount = lazy(() => import('views/RegisterAccount'));
const AppointmentCreate = lazy(() => import('views/AppointmentCreate'));
const ListAppointment = lazy(() => import('views/ListAppointment'));
const CallHistory = lazy(() => import('views/CallHistory'));
const JoinMeet = lazy(() => import('views/JoinMeet'));
const ListCall = lazy(() => import('views/ListCall'));
const CallingManage = lazy(() => import('views/CallingManage'));
const RecordManage = lazy(() => import('views/RecordManage'));

//* For secured route
const routes = [
  { path: RouteBase.Test, exact: true, name: 'Test', component: withErrorBoundary(Test) },

  {
    path: RouteBase.RegisterAccount,
    exact: true,
    name: 'RegisterAccount',
    component: withErrorBoundary(RegisterAccount),
  },

  {
    path: RouteBase.AppointmentCreate,
    exact: true,
    name: 'AppointmentCreate',
    component: withErrorBoundary(AppointmentCreate),
  },

  {
    path: RouteBase.ListAppointment,
    exact: true,
    name: 'ListAppointment',
    component: withErrorBoundary(ListAppointment),
  },

  {
    path: RouteBase.CallHistory,
    exact: true,
    name: 'CallHistory',
    component: withErrorBoundary(CallHistory),
  },

  {
    path: RouteBase.JoinMeet,
    exact: true,
    name: 'JoinMeet',
    component: withErrorBoundary(JoinMeet),
  },

  {
    path: RouteBase.ListCall,
    exact: true,
    name: 'ListCall',
    component: withErrorBoundary(ListCall),
  },

  {
    path: RouteBase.CallingManage,
    exact: true,
    name: 'CallingManage',
    component: withErrorBoundary(CallingManage),
  },

  {
    path: RouteBase.RecordManage,
    exact: true,
    name: 'RecordManage',
    component: withErrorBoundary(RecordManage),
  },

  { name: '404', component: withErrorBoundary(Page404) },
];

export default routes;
