export const GET_TODOS_URL = 'https://jsonplaceholder.typicode.com/todos';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_IMAGE = 'http://103.226.250.81:8700/';
export const LOGIN_URL = BASE_URL + '/auth/login';
export const REGISTER_URL = BASE_URL + '/auth/register';
export const CREATE_USER_URL = BASE_URL + '/create-user';
export const CALL_URL = BASE_URL + '/call';
export const CALL_TYPE_URL = BASE_URL + '/call-type';
export const UPLOAD_SINGLE_URL = BASE_URL + '/upload/single';



export const DEVICE_URL = BASE_URL + '/device';
export const DEACTIVATE_URL = BASE_URL + '/configuration';
export const STATISTIC_URL = BASE_URL + '/statistics';
export const LOG_REQUEST_URL = BASE_URL + '/otter-get-response-log/otter-get-response/list';
export const LOG_REQUEST_DEVICE_URL = BASE_URL + '/otter-get-response-log';
export const LIST_ACCOUNT_URL = BASE_URL + '/auth/list';
export const CREATE_ACCOUNT_URL = BASE_URL + '/auth/create-user';
export const AUTH_URL = BASE_URL + '/auth';

export const COUNT_NOTI_URL = BASE_URL + '/notifications/count';
export const NOTI_URL = BASE_URL + '/notifications';













