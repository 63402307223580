import * as React from 'react';

const IconDoc = (props) => (
  <svg width={20} height={20} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.276 0h7.45C14.47 0 16 1.424 16 3.864v8.264C16 14.608 14.471 16 11.725 16h-7.45C1.574 16 0 14.608 0 12.128V3.864C0 1.424 1.573 0 4.276 0Zm.24 3.728V3.72h2.657c.383 0 .694.28.694.623 0 .353-.311.633-.694.633H4.516c-.383 0-.694-.28-.694-.624s.31-.624.694-.624Zm0 4.864h6.969c.382 0 .693-.28.693-.624s-.311-.625-.693-.625h-6.97c-.382 0-.693.28-.693.625 0 .344.31.624.694.624Zm0 3.656h6.968c.355-.032.623-.305.623-.624 0-.328-.268-.6-.623-.632H4.516a.725.725 0 0 0-.667.296.583.583 0 0 0 0 .672c.142.2.4.32.667.288Z"
      fill="#547997"
    />
  </svg>
);

export default IconDoc;
