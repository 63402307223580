import React, { Fragment, Suspense, useState } from 'react';
import PrivateRoute from 'components/PrivateRoute';
import routes from 'routes/routes';
import { Route, Routes, useLocation, matchPath } from 'react-router-dom';
import LayoutWithDrawerAndAppbar from 'components/LayoutWithDrawerAndAppbar';
import { adminMenu, userMenu } from 'constants/leftmenu';
import HeaderProfile from 'components/HeaderProfile';
import HeaderLayoutContent from 'components/HeaderLayoutContent';
import { CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthentication } from 'providers/AuthenticationProvider';
import { roles } from 'constants/index';

const useStyle = makeStyles((theme) => ({
  loading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const DefaultLayout = (props) => {
  //! State
  const path = useLocation().pathname;
  const classes = useStyle();
  const { userInfo } = useAuthentication();
  const role = userInfo?.userRole;
  let menuSidebar = [];
  if (role === roles.ADMIN) {
    menuSidebar = adminMenu;
  }
  if (role === roles.USER) {
    menuSidebar = userMenu;
  }
  let label = '';
  const { pathname, state } = useLocation();

  menuSidebar.forEach((item) => {
    Object.keys(item).forEach((key) => {
      item[key].map((elm) => {
        if (elm?.path === pathname) {
          if (elm?.titleList) {
            label = elm?.titleList;
            return;
          }
          label = elm?.label;
          return;
        } else {
          if (
            matchPath(
              {
                path: `${elm?.secondPath}`,
              },
              pathname.toString(),
            ) !== null
          ) {
            const id = matchPath(
              {
                path: `${elm?.secondPath}`,
              },
              pathname.toString(),
            )?.params?.id;
            label = elm.titleDetail ? `${elm?.titleDetail} ${state}` : '';
            return;
          }
        }
      });
    });
  });

  //! Function

  //! Render
  return (
    <Fragment>
      <LayoutWithDrawerAndAppbar
        header={<HeaderProfile />}
        headerContent={<HeaderLayoutContent header={label} title="" />}
        leftMenu={menuSidebar}
      >
        <Suspense
          fallback={
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            {routes.map((route, idx, arr) => {
              return (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  element={
                    route.isPrivateRoute ? (
                      <PrivateRoute>
                        <route.component />
                      </PrivateRoute>
                    ) : (
                      <route.component />
                    )
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </LayoutWithDrawerAndAppbar>
    </Fragment>
  );
};

export default DefaultLayout;
