import IconDoc from 'components/CommonIcons/IconMenu/IconDoc';
import { RouteBase } from './routeUrl';
import IconDecentralization from 'components/CommonIcons/IconMenu/IconDecentralization';
import CommonIcons from 'components/CommonIcons';
import IconCategory from 'components/CommonIcons/IconMenu/IconCategory';
import IconChart from 'components/CommonIcons/IconMenu/IconChart';
import IconPhone from 'components/CommonIcons/Icons/IconPhone';

export const adminMenu = [
  {
    'DATA PERMISSIONS': [
      {
        label: 'Join Meet',
        icon: IconPhone,
        path: RouteBase.JoinMeet,
      },
      {
        label: 'List Call',
        icon: IconDoc,
        path: RouteBase.ListCall,
      },
      {
        label: 'Calling Manage',
        icon: IconDecentralization,
        path: RouteBase.CallingManage,
      },
      {
        label: 'Record Manage',
        icon: IconCategory,
        path: RouteBase.RecordManage,
      },
    ],
  },
];

export const userMenu = [
  {
    'DATA PERMISSIONS': [
      {
        label: 'Register Appointment',
        icon: IconChart,
        path: RouteBase.AppointmentCreate,
      },
      {
        label: 'List Appointment',
        icon: IconCategory,
        path: RouteBase.ListAppointment,
      },
      {
        label: 'Call History',
        icon: IconChart,
        path: RouteBase.CallHistory,
      },
    ],
  },
];
