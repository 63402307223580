import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import HttpService from 'services/httpServices';
import { showError, showSuccess } from 'helpers/toast';
import { LOGIN_URL } from 'constants/api';

const AuthenticationContext = createContext({
  token: '',
  isLogged: false,
  isLoggingOut: false,
  login: () => {},
  logout: () => {},
  serviceSelected: '',
  chooseService: () => {},
  userInfo: '',
});

export const useAuthentication = () => useContext(AuthenticationContext);

const AuthProvider = ({ children }) => {
  //! State

  const tokenLocalStorage = HttpService.getTokenSession();
  const servicesLocalStorage = HttpService.getServiceStorage();
  const userInfoStorage = HttpService.getUserInfoStorage();

  const [isLogged, setIsLogged] = useState(tokenLocalStorage ? true : false);
  const [isLoggingOut, setLoggingOut] = useState(false);
  const [serviceSelected, setServiceSelected] = useState(servicesLocalStorage ? servicesLocalStorage : '');
  const [userInfo, setUserInfo] = useState(userInfoStorage || '');
  const [token, setToken] = useState(tokenLocalStorage || '');

  useEffect(() => {
    const service = HttpService.getServiceStorage();
    if (tokenLocalStorage) {
      setIsLogged(true);
      HttpService.attachTokenToHeader(tokenLocalStorage);
    }
    if (service) setServiceSelected(service);
  }, []);

  //! Function
  const chooseService = useCallback((arg) => {
    setServiceSelected(arg);
  }, []);

  const login = useCallback(async ({ username, password, remember, onLoading }) => {
    try {
      onLoading(true);

      //* Get access token
      const res = await HttpService.post(LOGIN_URL, { username, password });
      const { accessToken, user } = res.data.data;
      const role = user?.userRole;
      // remember && HttpService.saveTokenStorage(accessToken);
      HttpService.saveTokenSession(accessToken);
      HttpService.attachTokenToHeader(accessToken);
      HttpService.saveUserInfoStorage(user);
      remember && HttpService.saveUserInfoLocalStorage(user);
      !remember && localStorage.removeItem('user');

      showSuccess('Login successfully');
      setToken(accessToken);
      setUserInfo(user);
      setIsLogged(true);
      onLoading(false);
    } catch (error) {
      showError(error?.response?.data.messages[0] || 'Account does not exist!', { autoClose: 3000 });
      onLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoggingOut(true);
        HttpService.clearUserInfoStorage();
        HttpService.clearTokenSession();
        HttpService.clearServiceStorage();
        sessionStorage.removeItem('path');
        window.location.reload();
        resolve();
      } catch (error) {
        showError(error.toString());
        setLoggingOut(false);
        reject(error);
      }
    });
  }, []);

  //! Render
  const value = useMemo(() => {
    return {
      isLogged,
      isLoggingOut,
      login,
      logout,
      serviceSelected,
      chooseService,
      token,
      userInfo,
    };
  }, [isLogged, login, logout, serviceSelected, isLoggingOut, chooseService, token]);

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export default AuthProvider;
