import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CommonStyles from '.';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { enUS } from 'date-fns/locale';

const useStyles = makeStyles((theme) => {
  return {
    desktopView: {
      textTransform: 'capitalize',
    },
  };
});

const DatePickerField = (props) => {
  const { name, onBlur, helperText, readOnlyInput, labelDatePicker, clickTextFieldShowModal, ...restProps } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const formats = {
    monthAndYear: 'MM/yyyy',
  };
  const handleOpenDate = () => {
    setOpen((prev) => !prev);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS} dateFormats={formats}>
      <DatePicker
        open={clickTextFieldShowModal ? open : undefined}
        // onOpen={() => setOpen(true)}
        onClose={handleOpenDate}
        label={labelDatePicker}
        ampm={false}
        views={['year', 'month', 'day']}
        PopperProps={{
          className: classes.desktopView,
        }}
        renderInput={(props) => {
          props.inputProps.readOnly = readOnlyInput;
          props.inputProps.placeholder = 'DD/MM/YYYY';
          return (
            <CommonStyles.TextField
              {...props}
              name={name}
              // onBlur={onBlur}
              helperText={helperText}
              error={!!helperText}
              onClick={handleOpenDate}
            />
          );
        }}
        {...restProps}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
