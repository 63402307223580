import * as React from 'react';

const IconCategory = (props) => (
  <svg width={20} height={20} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.44 8.602c1.057 0 1.905.856 1.905 1.921v2.557A1.91 1.91 0 0 1 4.44 15H1.905A1.916 1.916 0 0 1 0 13.08v-2.557c0-1.065.855-1.92 1.905-1.92H4.44Zm8.655 0c1.05 0 1.905.856 1.905 1.921v2.557c0 1.057-.855 1.92-1.905 1.92H10.56a1.91 1.91 0 0 1-1.905-1.92v-2.557c0-1.065.848-1.92 1.905-1.92h2.535ZM4.44 0c1.057 0 1.905.863 1.905 1.92v2.558c0 1.065-.848 1.92-1.905 1.92H1.905A1.911 1.911 0 0 1 0 4.478V1.92C0 .863.855 0 1.905 0H4.44Zm8.655 0C14.145 0 15 .863 15 1.92v2.558c0 1.065-.855 1.92-1.905 1.92H10.56a1.906 1.906 0 0 1-1.905-1.92V1.92C8.655.863 9.503 0 10.56 0h2.535Z"
      fill="#547997"
    />
  </svg>
);

export default IconCategory;
