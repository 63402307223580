import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import InputField from 'components/CustomField/InputField';
import { Navigate, useNavigate } from 'react-router-dom';
import { RouteBase } from 'constants/routeUrl';
import { useAuthentication } from 'providers/AuthenticationProvider';
import CommonStyles from 'components/CommonStyles';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { REQUIRED_FIELD } from 'helpers/messages';
import login_image from 'assets/images/login/login_image.svg';
import login_background from 'assets/images/login/login_background.svg';
import { Box } from '@mui/system';
import CustomField from 'components/CustomField';
import { Grid } from '@mui/material';
import { useGetCallType } from 'hooks/call/useGetCallType';
import { useAddCall } from 'hooks/call/useAddCall';
import { showError, showInfo } from 'helpers/toast';
import httpServices from 'services/httpServices';
import { PhotoCamera } from '@mui/icons-material';
import { encodeImageFileAsURL } from 'helpers';
import { useUploadLogo } from 'hooks/upload/useUploadLogo';
import { BASE_URL } from 'constants/api';

const useStyles = makeStyles((theme) => {
  return {
    backgroundVector: {
      backgroundImage: `url(${login_image})`,
      backgroundSize: 'cover',
      position: 'absolute',
      bottom: 0,
      right: 0,
      height: '100%',
      width: '40%',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    loginForm: {
      padding: '4.5rem 4rem',
      backgroundColor: '#fff',
      borderRadius: '12px',
      zIndex: '2',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        minWidth: '350px',
        width: '100%',
        padding: '1.5rem 1rem',
      },
      '& .login_header': {
        color: theme.custom.colors.green,
        fontWeight: '600 !important',
        textAlign: 'center',
      },
      '& .form_group': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '4rem',
        '& .form_input_group': {
          display: 'flex',
          gap: '1.5rem',
          flexDirection: 'column',
          marginBottom: '1.5rem',
          '& .form_input': {
            padding: '12px 16px',
            color: theme.custom.colors.darkgray,
            borderRadius: '12px',
            border: `solid 1px ${theme.custom.colors.darkgray}`,
          },
        },
        '& .form_checkbox': {
          display: 'flex',
          flexDirection: 'row',
          gap: '.5rem',
          alignItems: 'center',
          color: theme.custom.colors.darkgray,
          marginBottom: '1.5rem',
        },
        '& .form_submit': {
          padding: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: theme.custom.colors.darkblue,
          borderRadius: '12px',
          color: theme.custom.colors.white,
        },
        '& .btnGroup': {
          marginTop: '1rem',
          gap: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      },
    },
    form: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      display: 'flex',
      //   gap: '110px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5F5F5',
    },
    eachRow: {
      marginBottom: theme.spacing(),
    },
    avatarTitle: {
      fontWeight: 600,
      color: theme.custom.colors.green,
      marginBottom: '8px',
    },
    logo: {
      '& > img': {
        with: 100,
        height: 100,
        objectFit: 'cover',
      },
    },
  };
});

const AppointmentCreate = (props) => {
  //! State
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isLogged } = useAuthentication();
  const userInfo = httpServices.getUserInfoStorage();

  const { isLoading: isLoadingUpload, mutateAsync: uploadLogo } = useUploadLogo();
  const { data: resLogRequest, isLoading, error, refetch } = useGetCallType();
  const { isLoading: isAddCalling, mutateAsync: addCall } = useAddCall();

  const optionsDurations = resLogRequest?.data?.data.map((el, index) => {
    return {
      label: el?.title,
      value: el?.id,
    };
  });

  const initValuesForm = isLogged
    ? {
        name: '',
        email: userInfo?.email,
        phoneNumber: '',
        timeCall: '',
        callDuration: '',
      }
    : {
        name: '',
        email: '',
        phoneNumber: '',
        timeCall: '',
        callDuration: '',
      };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_name'))),
    email: Yup.string()
      .email('Please enter a valid email address!')
      .required(REQUIRED_FIELD(i18n.t('common:appointment_email'))),
    phoneNumber: Yup.number()
      .typeError('Please re-enter the phone number format!')
      .required(REQUIRED_FIELD(i18n.t('common:appointment_phoneNumber'))),
    timeCall: Yup.date()
      .typeError('Please re-enter the call time format!')
      .required(REQUIRED_FIELD(i18n.t('common:appointment_timeCall'))),
    callDuration: Yup.string().required(REQUIRED_FIELD(i18n.t('common:appointment_callDuration'))),
  });

  //! Function

  const handleSubmit = async (values) => {
    const data = {
      title: values?.name,
      type: values?.callDuration,
      userEmail: values?.email,
      phoneNumber: values?.phoneNumber,
      appointmentDate: values?.timeCall,
    };
    try {
      await addCall(data, {
        onSuccess: (response) => {
          showInfo(t('common:appointment_addSuccess'));
          if (isLogged) {
            setTimeout(() => {
              navigate(RouteBase.ListAppointment);
            }, 1000);
          }
        },
        onError: (error) => {
          showError(error?.response?.data.messages[0] || 'Error. Please try again!', { autoClose: 3000 });
        },
      });
    } catch (error) {
      showError(error?.response?.data.messages[0] || 'Error. Please try again!', { autoClose: 3000 });
    }
  };

  const handleClickCancel = (setFieldValue) => {
    setFieldValue('name', '');
    setFieldValue('email', '');
    setFieldValue('phoneNumber', '');
    setFieldValue('timeCall', '');
    setFieldValue('callDuration', '');
  };

  const handleClickLogin = () => {
    navigate(RouteBase.Login);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initValuesForm}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(propsFormik) => {
        return (
          <Form className={classes.form}>
            <Box className={classes.backgroundVector}></Box>
            <Box className={classes.loginForm}>
              <CommonStyles.Typography className="login_header" variant="h2" component="h2">
                {t('common:appointment_register')}
              </CommonStyles.Typography>
              <Box className="form_group">
                <Box className="form_input_group">
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="name"
                        LabelColorPrimary
                        component={InputField}
                        label={t('common:appointment_name')}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        name="email"
                        LabelColorPrimary
                        component={InputField}
                        label={t('common:appointment_email')}
                        disabled={isLogged}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        name="phoneNumber"
                        LabelColorPrimary
                        component={CustomField.InputField}
                        label={t('common:appointment_phoneNumber')}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        name="timeCall"
                        LabelColorPrimary
                        component={CustomField.DatePickerField}
                        label={t('common:appointment_timeCall')}
                        minDate={new Date()}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        name="callDuration"
                        LabelColorPrimary
                        component={CustomField.SelectField}
                        options={optionsDurations}
                        label={t('common:appointment_callDuration')}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className="btnGroup">
                  <CommonStyles.Button variant="outlined" onClick={() => handleClickCancel(propsFormik.setFieldValue)}>
                    {t('common:cancel')}
                  </CommonStyles.Button>
                  {isLogged ? (
                    <CommonStyles.Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                      {t('common:back')}
                    </CommonStyles.Button>
                  ) : (
                    <CommonStyles.Button variant="contained" color="error" onClick={handleClickLogin}>
                      {t('common:login')}
                    </CommonStyles.Button>
                  )}
                  <CommonStyles.Button type="submit" isLoading={isAddCalling}>
                    {t('common:register')}
                  </CommonStyles.Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AppointmentCreate;
