import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CommonStyles from 'components/CommonStyles';
import { Badge, Box, List, ListItem } from '@mui/material';
import CommonIcons from 'components/CommonIcons';
import LogoutConfirm from './LogoutConfirm';
import { useLocation, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useAuthentication } from 'providers/AuthenticationProvider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '40px',
    },
    headerNameUser: {
      fontWeight: 600,
      color: '#434D56',
    },
    left: {
      display: 'flex',
      alignItems: 'center',
      '& .name-folder': {
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '32px',
        color: '#034B5E',
      },
      '& .title-folder': {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '21px',
        color: '#919499',
      },
    },
    buttonCancel: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
    },
    right: {
      color: theme.custom.colors.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .user-info': {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',

        fontWeight: 'bold',
        '& .img': {
          width: 36,
          height: 36,
          borderRadius: '50%',
          objectFit: 'cover',
        },
      },
      '& .btn-icon': {
        padding: 0,
        fontSize: 20,
        backgroundColor: theme.custom.colors.gray,
        color: theme.custom.colors.black,
        marginRight: '6px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#d9d9d9 ',
          boxShadow: 'none',
        },
      },
      '& .btn-active': {
        backgroundColor: '#d9d9d9 ',
      },
    },
    buttonRegister: {},
  };
});

const HeaderLayoutContent = (props) => {
  //! State
  const { header, title, sx, sxHeader, headerNameUser } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const [contentPopper, setContentPopper] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { token } = useAuthentication();
  const checkPath = location.pathname.split('/');

  //! Function
  const handleOpenPopper = (event, value) => {
    setContentPopper(value);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setContentPopper();
    setAnchorEl(null);
  };

  //! Render
  return (
    <div className={classes.footer} style={sx}>
      <div className={classes.left}>
        {(checkPath.length == 3 || checkPath.length == 4) && (
          <Box className={classes.buttonCancel} onClick={() => navigate(-1)}>
            <CommonStyles.Button>
              <CommonIcons.ArrowBackIcon />
            </CommonStyles.Button>
          </Box>
        )}
        <div className="name-folder" style={sxHeader}>
          {header}
          <span className={classes.headerNameUser}>{headerNameUser}</span>
        </div>
        <div className="title-folder">{title}</div>
      </div>
      <div className={classes.right}>
        <LogoutConfirm />
      </div>
    </div>
  );
};

export default HeaderLayoutContent;
